import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import get from 'lodash/get';
import Layout from '../components/layout';
import SEO from '../components/seo';
import DrinksList from '../components/DrinksList';

const VodkaPage = () => {
  const data = useStaticQuery(graphql`
    query VodkaPageQuery {
      allMongodbCocktailmeisterDrinks(
        limit: 120
        sort: { fields: image }
        filter: { ingredients: { elemMatch: { _0: { regex: "/Vodka/" } } } }
      ) {
        edges {
          node {
            id
            name
            slug
            ingredients {
              _0
              _1
            }
            instructions
            category
            alcoholic
            color
            color1
            glass
            occasions
            skill
            tastes
            tools
            image
          }
        }
      }
    }
  `);
  const drinks = get(data, 'allMongodbCocktailmeisterDrinks.edges');
  return (
    <Layout>
      <SEO title="Vodka cocktails" />
      <h1>Vodka cocktails</h1>
      <p>Here are all the vodka cocktails you can think of.</p>
      <DrinksList drinks={drinks} />
    </Layout>
  );
};

export default VodkaPage;
